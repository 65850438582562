import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import Database from 'shared/db/services/Database.web';
import Event from 'shared/db/services/Event';
import { EventModel } from 'shared/types/Events';

import { FIRESTORE_COLLECTION } from '@/constants/firestore';
import { synchronizeDB } from '@/services/database/dbSync';
import { FirebaseFirestoreAPI } from '@/services/firebase/firestore';
import Logger from '@/services/logger';

import { useDBSyncContext } from './DBSyncContext';
import { useImagesContext } from './ImagesContext';
import { useOrganisationsContext } from './OrganisationsContext';
import { useUserContext } from './UserContext';

export type ContextValue = {
    attentionNeededEvents: EventModel[];
};

export type Props = {
    children: React.ReactNode;
};

const EventInvitationContext = createContext<ContextValue>({
    attentionNeededEvents: [],
});

const database = Database.getDatabase();

const EventInvitationContextProvider = ({ children }: Props) => {
    const [attentionNeededEvents, setAttentionNeededEvents] = useState<
        EventModel[]
    >([]);

    const { ImagesService } = useImagesContext();
    const { setHasSyncIssue, setIsSyncInProgress } = useDBSyncContext();
    const { userOrganisation } = useOrganisationsContext();
    const { userProfileData } = useUserContext();
    const initialInvitationsSyncedRef = useRef(false);

    useEffect(() => {
        if (!userOrganisation?.id) return;

        // Reset ref on organisation id change
        initialInvitationsSyncedRef.current = false;

        const unsubscribe = FirebaseFirestoreAPI.getCollectionRealtimeUpdates(
            FIRESTORE_COLLECTION.invitations,
            userOrganisation?.id || '',
            async () => {
                if (!initialInvitationsSyncedRef.current) {
                    initialInvitationsSyncedRef.current = true;
                    return;
                }

                setIsSyncInProgress(true);

                await synchronizeDB(database, {
                    setIsLastSyncFailed: setHasSyncIssue,
                });

                setIsSyncInProgress(false);
            },
        );

        return () => unsubscribe();
    }, [setHasSyncIssue, setIsSyncInProgress, userOrganisation?.id]);

    useEffect(() => {
        if (!userOrganisation?.id || !userProfileData?.id) {
            return setAttentionNeededEvents([]);
        }

        const eventService = new Event({
            database,
            imageService: ImagesService,
            logDBAction: Logger.logRecordActivity,
            notificationsService: {
                cancelNotification: () => null,
                scheduleEventLocalNotification: () => Promise.resolve(),
            },
            cacheService: {
                clear: () => Promise.resolve(),
                get: () => Promise.resolve() as any,
            },
        });

        const attentionNeedingEventsSubscription = eventService
            .getAttentionNeededEvents(userOrganisation?.id, userProfileData?.id)
            .subscribe((events) => setAttentionNeededEvents(events));

        return () => {
            attentionNeedingEventsSubscription.unsubscribe();
        };
    }, [ImagesService, userOrganisation?.id, userProfileData?.id]);

    return (
        <EventInvitationContext.Provider
            value={{
                attentionNeededEvents,
            }}
        >
            {children}
        </EventInvitationContext.Provider>
    );
};

function useEventInvitationContext() {
    const context = useContext(EventInvitationContext);

    return context;
}

export {
    EventInvitationContext,
    EventInvitationContextProvider,
    useEventInvitationContext,
};
