import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    TextField,
    tooltipClasses,
    Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import React, {
    ChangeEvent,
    memo,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AppAnalytics } from 'shared/analytics/app';
import { CountryCode } from 'shared/constants/countries';
import { FIREBASE_AUTH_ERROR_CODE } from 'shared/constants/firebase/errors';
import { LOCALE } from 'shared/constants/localization/types';
import AppService from 'shared/db/services/App';
import { Region } from 'shared/types/regions';

import {
    LocaleSelector,
    PhoneNumber,
    Tooltip,
    UnveilPasswordButton,
} from '@/components';
import InputBadge from '@/components/InputBadge';
import { CUSTOM_ERROR } from '@/constants/customErrors';
import { useAuthContext } from '@/context/AuthContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { useLocalizationContext } from '@/context/LocalizationContext';
import { getUserDefaultOrganisation } from '@/helpers/organisations';
import { useDatabase } from '@/hooks';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import Logger from '@/services/logger';
import { ServerAuthAPI } from '@/services/networking/auth';
import { Snackbar } from '@/services/toastNotifications';
import { saveLastUserEmailInLocalStorage } from '@/services/webStorage/localStorage/user';
import { COLOR } from '@/theme/colors';
import { SignUpFormValues } from '@/types/auth';
import { handleSignUpFlowError } from '@/utils/auth';
import { AuthValidationSchema } from '@/validations/auth';

import AcceptTermsCheckbox from '../../../../components/AcceptTermsCheckbox';

import { ServerErrorAlert } from './components';
import { Props } from './types';

const TEXT_FIELD_HEIGHT = 48;

const isInvalidAccessCodeError = (error: unknown): boolean => {
    return (
        error instanceof AxiosError &&
        error?.response?.data?.errors?.access_code?.[0] === 'invalid'
    );
};

const getInitialSignUpRegion = (
    validAccessCode: Props['validAccessCode'],
    selectedRegionCode: Props['selectedRegionCode'],
) => {
    if (validAccessCode) {
        return CountryCode['unitedStates'];
    }

    if (selectedRegionCode) {
        return selectedRegionCode;
    }

    return CountryCode['australia'];
};

const getInitialLanguage = (
    regions: Region[],
    selectedRegionCode: Props['selectedRegionCode'],
) =>
    regions.find((region) => region.code === selectedRegionCode)
        ?.proposed_language || LOCALE.en_AU;

function SignUpForm({
    handleSelectCountry,
    validAccessCode,
    resetAccessCode,
    selectedRegionCode,
}: Props) {
    const { regions } = useLocalizationContext();
    const initialLanguage = getInitialLanguage(regions, selectedRegionCode);
    const initialRegion = getInitialSignUpRegion(
        validAccessCode,
        selectedRegionCode,
    );

    const { requireUnitedStatesEarlyAccess } = useFeatureFlagsContext();

    const initialValues: SignUpFormValues = {
        companyName: '',
        email: '',
        emailSubscription: true,
        firstName: '',
        language: initialLanguage,
        lastName: '',
        password: '',
        phone: '',
        prefix: '',
        region: initialRegion,
        acceptTerms: false,
    };

    const {
        allowUserToEnter,
        authenticateUser,
        invalidateUserAuth,
        setIsUserAfterSignUp,
    } = useAuthContext();
    const { getDatabase } = useDatabase();
    const { t } = useTranslation();

    const { setIsInitialSyncInProgress } = useDBSyncContext();

    const [authErrorCode, setAuthErrorCode] = useState<
        number | FIREBASE_AUTH_ERROR_CODE | null
    >(null);
    const [isAuthPending, setIsAuthPending] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleOnSubmit = useCallback(
        async (values: SignUpFormValues) => {
            setAuthErrorCode(null);
            setIsAuthPending(true);

            try {
                const database = getDatabase();
                const appService = new AppService({
                    database,
                    logInfo: Logger.logInfo,
                });

                await FirebaseAuthAPI.signUp({
                    email: values.email,
                    password: values.password,
                });

                const signUpParams = {
                    app_subscription: true,
                    company_name: values.companyName || null,
                    email_subscription: values.emailSubscription,
                    email: values.email,
                    first_name: values.firstName,
                    language: values.language,
                    last_name: values.lastName,
                    password: values.password,
                    password_confirmation: values.password,
                    phone: values.phone.substring(values.prefix.length + 1),
                    phone_prefix: '+'.concat(values.prefix),
                    region: values.region,
                };

                if (
                    validAccessCode &&
                    values.region === CountryCode['unitedStates']
                ) {
                    Object.assign(signUpParams, {
                        access_code: validAccessCode,
                    });
                }

                await ServerAuthAPI.signUpWithFirebase(signUpParams);

                const firebaseToken = await FirebaseAuthAPI.getAuthToken();

                if (!firebaseToken) {
                    throw new Error(CUSTOM_ERROR.no_firebase_token_found);
                }

                await appService.clearDatabase();

                authenticateUser({
                    allowUserToEnter: false,
                    firebaseToken,
                    rememberUser: true,
                });

                const userDefaultOrganisation =
                    await getUserDefaultOrganisation();

                if (!userDefaultOrganisation?.id) {
                    throw new Error(CUSTOM_ERROR.no_user_default_org_found);
                }

                await FirebaseAuthAPI.setUserOrganisationId(
                    userDefaultOrganisation.id,
                );

                allowUserToEnter();

                setIsUserAfterSignUp(true);
                saveLastUserEmailInLocalStorage(values.email);

                AppAnalytics.logUserRegistered(FirebaseAnalytics.logEvent);

                setIsInitialSyncInProgress(true);
            } catch (error) {
                if (isInvalidAccessCodeError(error)) {
                    resetAccessCode?.();

                    Snackbar.showToastNotification({
                        message: t(
                            'EarlyAccessForm:server_error:sign_up_code_invalid',
                        ),
                        options: {
                            variant: 'error',
                        },
                    });
                }

                invalidateUserAuth();

                handleSignUpFlowError({ error, setAuthErrorCode });
            } finally {
                setIsAuthPending(false);
            }
        },
        [
            getDatabase,
            validAccessCode,
            authenticateUser,
            allowUserToEnter,
            setIsUserAfterSignUp,
            setIsInitialSyncInProgress,
            invalidateUserAuth,
            resetAccessCode,
            t,
        ],
    );

    const form = useFormik<SignUpFormValues>({
        initialValues,
        enableReinitialize: true,
        onSubmit: handleOnSubmit,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        validationSchema: AuthValidationSchema.signUpForm,
    });

    const handleSignUpButtonClick = useCallback(() => {
        form.handleSubmit();
    }, [form]);

    const handleShowPasswordIconClick = useCallback(() => {
        setShowPassword((prev) => !prev);
    }, []);

    const handleEmailSubscriptionCheckboxClick = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            form.setFieldValue('emailSubscription', event.target.checked);
        },
        [form],
    );

    const setAcceptTermsTouched = useCallback(
        () =>
            setTimeout(() => {
                if (!form.touched.acceptTerms) {
                    form.setFieldTouched('acceptTerms', true, true);
                }
            }),
        [form],
    );

    const handleAcceptTermsCheckboxClick = useCallback(
        (checked: boolean) => {
            form.setFieldValue('acceptTerms', checked);
            setAcceptTermsTouched();
        },
        [form, setAcceptTermsTouched],
    );

    const onPhoneNumberChange = useCallback(
        (
            phone: string,
            data: {
                name: string;
                dialCode: string;
                countryCode: string;
            },
        ) => {
            form.setFieldValue('phone', phone);
            form.setFieldValue('prefix', data.dialCode);
        },
        [form],
    );

    const onLanguageChange = useCallback(
        (language: LOCALE) => form.setFieldValue('language', language),
        [form],
    );

    const onRegionChange = useCallback(
        (country: CountryCode) => {
            if (!validAccessCode) {
                handleSelectCountry(country);
            }

            form.setFieldValue('region', country);
        },
        [validAccessCode, form, handleSelectCountry],
    );

    const companyNameFieldTrailingContent = useMemo(() => {
        return (
            <Box sx={{ display: 'flex', gap: 2 }}>
                <InputBadge
                    text={t('Generic:optional')}
                    height={23}
                    opacity={0.45}
                    textColor={COLOR.badgeTextGrey}
                    fontSize={10}
                    testID="SignUp-CompanyName-Optional-Badge"
                />
                <Tooltip
                    title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                            {t('SignUp:label:business_name_tooltip')}
                        </span>
                    }
                    placement="bottom"
                    PopperProps={{
                        sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                                maxWidth: 'none',
                                width: 350,
                            },
                        },
                    }}
                >
                    <InfoOutlinedIcon
                        sx={{
                            display: 'flex',
                            alignSelf: 'center',
                            color: COLOR.bahamaBlue,
                        }}
                        data-test-id="SignUp-BusinessName-Tooltip-Icon"
                    />
                </Tooltip>
            </Box>
        );
    }, [t]);

    return (
        <Box
            component="form"
            onSubmit={form.handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
            }}
        >
            <Box
                sx={{
                    mb: 14,
                    maxWidth: 520,
                    ml: { xs: 4, md: 10 },
                    mr: { xs: 4, md: 0 },
                }}
            >
                <Box
                    sx={{
                        mb: 4,
                    }}
                >
                    <Typography
                        component="p"
                        sx={{ color: 'text.secondary' }}
                        variant="body2"
                    >
                        {requireUnitedStatesEarlyAccess
                            ? t(
                                  'SignUp:header:app_availability_early_access_text',
                              )
                            : t('SignUp:header:app_availability_text')}
                    </Typography>
                </Box>
                <LocaleSelector
                    onLanguageChange={onLanguageChange}
                    onRegionChange={onRegionChange}
                    languageValue={form.values.language}
                    regionValue={form.values.region}
                    testIdPrefix="SignUpPage"
                    countryOnly
                />
                {authErrorCode ? (
                    <ServerErrorAlert errorCode={authErrorCode} />
                ) : null}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 3,
                    }}
                >
                    <TextField
                        data-test-id="SignUpPage-FirstNameInput"
                        error={
                            !!form.errors.firstName && !!form.touched.firstName
                        }
                        fullWidth
                        helperText={
                            form.touched.firstName ? form.errors.firstName : ''
                        }
                        id="firstName"
                        InputProps={{
                            style: {
                                height: TEXT_FIELD_HEIGHT,
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                        label={t('SignUp:label:first_name')}
                        name="firstName"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        sx={{ mr: 1 }}
                        value={form.values.firstName}
                    />
                    <TextField
                        data-test-id="SignUpPage-LastNameInput"
                        error={
                            !!form.errors.lastName && !!form.touched.lastName
                        }
                        fullWidth
                        helperText={
                            form.touched.lastName ? form.errors.lastName : ''
                        }
                        id="lastName"
                        InputProps={{
                            style: {
                                height: TEXT_FIELD_HEIGHT,
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                        label={t('SignUp:label:last_name')}
                        name="lastName"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        sx={{ ml: 1 }}
                        value={form.values.lastName}
                    />
                </Box>
                <TextField
                    data-test-id="SignUpPage-CompanyNameInput"
                    error={
                        !!form.errors.companyName && !!form.touched.companyName
                    }
                    fullWidth
                    helperText={
                        form.touched.companyName ? form.errors.companyName : ''
                    }
                    id="companyName"
                    label={t('SignUp:label:business_name')}
                    InputProps={{
                        endAdornment: companyNameFieldTrailingContent,
                        style: {
                            height: TEXT_FIELD_HEIGHT,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    name="companyName"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    sx={{ mb: 3 }}
                    value={form.values.companyName}
                />
                <TextField
                    data-test-id="SignUpPage-EmailInput"
                    error={!!form.errors.email && !!form.touched.email}
                    fullWidth
                    helperText={form.touched.email ? form.errors.email : ''}
                    id="email"
                    InputProps={{
                        style: {
                            height: TEXT_FIELD_HEIGHT,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    label={t('SignUp:label:email_address')}
                    name="email"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    sx={{ mb: 3 }}
                    value={form.values.email}
                />
                <PhoneNumber
                    data-test-id="SignUpPage-PhoneInput"
                    error={!!form.errors.phone && !!form.touched.phone}
                    fullWidth
                    helperText={form.touched.phone ? form.errors.phone : ''}
                    id="phone"
                    InputProps={{
                        style: {
                            height: TEXT_FIELD_HEIGHT,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    label={t('SignUp:label:phone_number')}
                    name="phone"
                    onBlur={form.handleBlur}
                    onChange={onPhoneNumberChange}
                    defaultCountry={form.values.region?.toLowerCase()}
                    sx={{ mb: 3 }}
                />
                <TextField
                    data-test-id="SignUpPage-PasswordInput"
                    error={!!form.errors.password && !!form.touched.password}
                    fullWidth
                    helperText={
                        form.touched.password ? form.errors.password : ''
                    }
                    id="password"
                    InputProps={{
                        endAdornment: (
                            <UnveilPasswordButton
                                isPasswordVisible={showPassword}
                                onClick={handleShowPasswordIconClick}
                            />
                        ),
                        style: {
                            height: TEXT_FIELD_HEIGHT,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    label={t('SignUp:label:password')}
                    name="password"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    sx={{ mb: 1.5 }}
                    type={showPassword ? 'text' : 'password'}
                    value={form.values.password}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        mb: 1,
                    }}
                >
                    <AcceptTermsCheckbox
                        checked={form.values.acceptTerms}
                        onValueChange={handleAcceptTermsCheckboxClick}
                        hasError={
                            !!form.errors.acceptTerms &&
                            !!form.touched.acceptTerms
                        }
                    />
                    <FormControlLabel
                        componentsProps={{
                            typography: {
                                fontSize: 12,
                            },
                        }}
                        control={
                            <Checkbox
                                checked={form.values.emailSubscription}
                                onChange={handleEmailSubscriptionCheckboxClick}
                            />
                        }
                        label={t('SignUp:label:email_subscription')}
                        sx={{ mb: -0.5, mt: -0.5 }}
                    />
                </Box>
                <Button
                    data-test-id="SignUpPage-RegisterButton"
                    disabled={isAuthPending}
                    fullWidth
                    onClick={handleSignUpButtonClick}
                    size="large"
                    sx={{ fontWeight: 700, height: 48, mb: 2 }}
                    type="submit"
                    variant="contained"
                >
                    {isAuthPending ? (
                        <CircularProgress
                            data-test-id="SignUpPage-RegisterButton-CircularProgress"
                            color="inherit"
                            size={20}
                        />
                    ) : (
                        t('SignUp:button:register_account')
                    )}
                </Button>
            </Box>
        </Box>
    );
}

export default memo(SignUpForm);
