import { Collection, Database, Model, Q } from '@nozbe/watermelondb';
import { isEmpty } from 'lodash';
import { copyClassInstance } from '../../utils/classes';

import EventContact from './EventContact';
import EventContactReminder from './EventContactReminder';
import HorseContact from './HorseContact';
import Location from './Location';
import Organisation from './Organisation';
import {
    applyContactsFiltersQueries,
    getUnsafeContactsSearchQueries,
    getUpdatedFieldValue,
} from '../utils';
import { ContactModel, ContactPayload } from '../../types/Contacts';
import {
    ContactsFilterOptions,
    ContactsFiltersObject,
} from '../../types/contactsFilters';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import { EntryModel } from '../../types/Entries';
import { HorseModel } from '../../types/Horses';
import { HorseContactModel } from '../../types/HorseContact';
import { LocationModel } from '../../types/Location';
import { capitalizeWords, stripSpecialChars } from '../../utils/string';
import { SortObject, UnsafeQuery } from '../../types/sort';
import { FILTER_TYPE } from '../../types/filter';
import { WhereDescription } from '@nozbe/watermelondb/QueryDescription';

class Contact {
    private database: Database;
    private collection: Collection<ContactModel>;
    private table = 'contacts';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll(...queries: Q.Clause[]) {
        return this.collection.query(...queries).fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getByIDs(ids: Array<string>) {
        return this.collection.query([Q.where('id', Q.oneOf(ids))]).fetch();
    }

    observeCount() {
        return this.collection.query().observeCount();
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async getByEntries(entryIds: string[]) {
        return this.collection
            .query(
                Q.experimentalNestedJoin('horse_contacts', 'horses'),
                Q.experimentalNestedJoin('horses', 'entries'),
                Q.on(
                    'horse_contacts',
                    Q.on('horses', Q.on('entries', 'id', Q.oneOf(entryIds))),
                ),
            )
            .fetch();
    }

    async getContactEntries(id: string) {
        const entries: EntryModel[] = [];
        const contact = await this.collection.find(id);
        const contactHorses = await contact.horses.fetch();
        await Promise.all(
            contactHorses.map(async (horse) => {
                const horseEntries = await horse.entries.fetch();
                entries.push(...horseEntries);
            }),
        );
        return entries;
    }

    async getContactsUniqueHorses(
        contacts: ContactModel[],
        queries: Q.Clause[],
    ): Promise<HorseModel[]> {
        const horses: HorseModel[] = [];

        await Promise.all(
            contacts.map(async (contact) => {
                const contactHorses = await contact.horses
                    .extend(...queries)
                    .fetch();

                horses.push(...contactHorses);
            }),
        );

        return [...new Set(horses)];
    }

    public getFilterContactsQueries(
        sort?: SortObject | null,
        filters?: ContactsFiltersObject,
    ): [Q.Clause[], Q.Clause[]] {
        const queries: Q.Clause[] = [];

        if (filters) {
            queries.push(
                Q.experimentalJoinTables(['horse_contacts', 'invoices']),
                Q.experimentalNestedJoin('horse_contacts', 'horses'),
                Q.experimentalNestedJoin('horses', 'entries'),
                ...applyContactsFiltersQueries(filters),
            );
        }

        const sortQueries: Q.Clause[] = [];

        if (sort && sort.query && sort.value) {
            const sortQuery = sort.query(sort.value);

            sortQueries.push(sortQuery);
        }

        return [queries, sortQueries];
    }

    public getFilterContactsSearchLokiQuery({
        searchText,
        sort,
        includeBusinessName,
    }: Pick<
        ContactsFilterOptions,
        'searchText' | 'includeBusinessName' | 'sort'
    >): Q.Clause {
        const parsedSearchText = searchText.toLowerCase().trim();

        const unsafeSearchQueries = getUnsafeContactsSearchQueries(
            parsedSearchText,
            includeBusinessName,
        );

        //@ts-ignore
        return Q.unsafeLokiTransform((raws, loki) => {
            let result = raws;

            unsafeSearchQueries.forEach(
                (query: UnsafeQuery) => (result = query(result, loki)),
            );

            if (sort && sort.unsafeQuery)
                result = sort.unsafeQuery(result, sort.value, loki);

            return result;
        });
    }

    public getFilterContactsSearchSQLQuery({
        searchText,
        includeBusinessName,
    }: Pick<ContactsFilterOptions, 'searchText' | 'includeBusinessName'>) {
        const parsedSearchText = searchText.toLowerCase().trim();

        const sanitizedSearch = Q.sanitizeLikeString(parsedSearchText);

        if (!sanitizedSearch.length) return;

        const queries: Array<Q.SqlExpr | WhereDescription> = [
            Q.unsafeSqlExpr(
                `first_name || ' ' || ifnull(last_name, '') LIKE '${sanitizedSearch}%'`,
            ),
            Q.unsafeSqlExpr(
                `first_name || ' ' || ifnull(last_name, '') LIKE '% ${sanitizedSearch}%'`,
            ),
        ];

        if (includeBusinessName) {
            queries.push(
                Q.where('business_name', Q.like(`${sanitizedSearch}%`)),
                Q.where('business_name', Q.like(`% ${sanitizedSearch}%`)),
            );
        }

        return Q.or(
            // @ts-ignore
            ...queries,
        );
    }

    private filterContacts({
        searchText,
        filterType,
        sort,
        filters,
        includeBusinessName,
    }: ContactsFilterOptions) {
        const [filterQueries, sortQueries] = this.getFilterContactsQueries(
            sort,
            filters,
        );

        const queries = [...filterQueries];

        const searchQuery =
            filterType === FILTER_TYPE.LOKI
                ? this.getFilterContactsSearchLokiQuery({
                      searchText,
                      sort,
                      includeBusinessName,
                  })
                : this.getFilterContactsSearchSQLQuery({
                      searchText,
                      includeBusinessName,
                  });

        if (searchQuery) queries.push(searchQuery);

        return this.collection.query(...queries).extend(...sortQueries);
    }

    getFilteredContactsCount({
        searchText,
        filterType,
        filters,
        includeBusinessName = false,
    }: ContactsFilterOptions) {
        const query = this.filterContacts({
            searchText,
            filterType,
            filters,
            includeBusinessName,
        });

        return query.fetchCount();
    }

    getFilteredContacts({
        searchText,
        filterType,
        filters,
        sort,
        includeBusinessName = false,
    }: ContactsFilterOptions) {
        const query = this.filterContacts({
            searchText,
            filterType,
            sort,
            filters,
            includeBusinessName,
        });

        return query.fetch();
    }

    async add(payload: ContactPayload, userId: string) {
        const { horses, image, location } = payload;

        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        const locationService = new Location({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        let locationElement: LocationModel | undefined;

        if (location) {
            locationElement = await locationService.addUnique(location, userId);
        }

        const newContact = await this.database.write(async () => {
            const createdContact = await this.collection.create((contact) => {
                contact.firstName = capitalizeWords(payload.firstName);
                contact.lastName = capitalizeWords(payload.lastName);
                // `description` is not used
                contact.description = null;
                contact.role = payload.role;
                contact.phone = stripSpecialChars(payload.phone);
                contact.phonePrefix = payload.phonePrefix;
                contact.email = payload.email;
                contact.businessName = payload.businessName;
                contact.address = payload.address;
                contact.comments = payload.comments;
                contact.locationID = locationElement?.id
                    ? locationElement.id
                    : contact?.locationID;
                contact.billable = payload.billable || false;
                contact.hidden = payload.hidden || false;
                contact.userId = userId;
                contact.organisationId = organisationID;
            });

            this.options.logDBAction({
                message: 'Create contact',
                modelName: this.table,
                payload: createdContact,
            });

            if (horses?.length && horses.length > 0) {
                const horseContactsCollection =
                    this.database.collections.get<HorseContactModel>(
                        'horse_contacts',
                    );

                for (const horse of horses) {
                    const createdHorseContact =
                        await horseContactsCollection.create(
                            (horseContact: HorseContactModel) => {
                                horseContact.contactId = createdContact.id;
                                horseContact.userId = createdContact.userId;
                                horseContact.organisationId =
                                    createdContact.organisationId;
                                horseContact.horseId = horse.id;
                            },
                        );

                    this.options.logDBAction({
                        message: 'Create contact - create horse contact',
                        modelName: this.table,
                        payload: createdHorseContact,
                    });
                }
            }

            return createdContact;
        });

        if (image && image.uri) {
            // create a new entry in Firestore DB
            await this.options.imageService.uploadImage({
                image,
                entityID: newContact.id,
                entityType: 'Contact',
                annotationImage: '',
                ownerID: userId,
                userIDs: [userId],
                organisationID,
            });
        }

        return newContact;
    }

    async batchAdd(contacts: ContactPayload[], userId: string) {
        const locationService = new Location({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        let contactsToAdd: ContactModel[] = [];

        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        await this.database.write(async () => {
            await Promise.all(
                contacts.map(async (payload) => {
                    const { location } = payload;
                    let locationElement: LocationModel;

                    // create location if not already exists
                    if (location && !isEmpty(location)) {
                        const existingLocation =
                            await locationService.getByParam(
                                'place_id',
                                location.placeID,
                            );

                        if (existingLocation.length > 0) {
                            locationElement = existingLocation[0];
                        } else {
                            const locationCollection =
                                this.database.collections.get<LocationModel>(
                                    'locations',
                                );

                            locationElement = await locationCollection.create(
                                (loc) => {
                                    loc.placeID = location.placeID;
                                    loc.name = location.name;
                                    loc.lat = `${location.lat}`;
                                    loc.lng = `${location.lng}`;
                                    loc.url = location.url;
                                    loc.utcOffset = location.utcOffset;
                                    loc.userId = userId;
                                    loc.organisationId = organisationID;
                                },
                            );

                            this.options.logDBAction({
                                message:
                                    'Create batch contacts - create location',
                                modelName: this.table,
                                payload: locationElement,
                            });
                        }
                    }

                    const contactToAdd = await this.collection.prepareCreate(
                        (contact) => {
                            contact.firstName = capitalizeWords(
                                payload.firstName,
                            );
                            contact.lastName = capitalizeWords(
                                payload.lastName,
                            );
                            // `description` is not used
                            contact.description = null;
                            contact.role = payload.role || null;
                            contact.phone = stripSpecialChars(payload?.phone);
                            contact.phonePrefix = payload.phonePrefix;
                            contact.email = payload.email;
                            contact.address = payload.address;
                            contact.comments = payload.comments || null;
                            contact.locationID = !isEmpty(locationElement)
                                ? locationElement.id
                                : null;
                            contact.billable = payload.billable || false;
                            contact.hidden = payload.hidden || false;
                            contact.userId = userId;
                            contact.organisationId = organisationID;
                        },
                    );

                    contactsToAdd = [...contactsToAdd, contactToAdd];

                    const { horses } = payload;

                    if (horses?.length && horses.length > 0) {
                        const horseContactsCollection =
                            this.database.collections.get<HorseContactModel>(
                                'horse_contacts',
                            );

                        await Promise.all(
                            horses.map(async (horse) => {
                                const createdHorseContact =
                                    await horseContactsCollection.create(
                                        (horseContact: HorseContactModel) => {
                                            horseContact.contactId =
                                                contactToAdd.id;
                                            horseContact.userId =
                                                contactToAdd.userId;
                                            horseContact.organisationId =
                                                contactToAdd.organisationId;
                                            horseContact.horseId = horse.id;
                                        },
                                    );

                                this.options.logDBAction({
                                    message:
                                        'Create batch contacts - create horse contact',
                                    modelName: this.table,
                                    payload: createdHorseContact,
                                });
                            }),
                        );
                    }

                    const { image } = payload;
                    if (image && image.uri) {
                        // create a new entry in Firestore DB
                        await this.options.imageService.uploadImage({
                            image,
                            entityID: contactToAdd.id,
                            entityType: 'Contact',
                            annotationImage: '',
                            ownerID: userId,
                            userIDs: [userId],
                            organisationID,
                        });
                    }
                }),
            );

            await this.database.batch(...contactsToAdd);

            this.options.logDBAction({
                message: 'Create batch contacts',
                modelName: this.table,
                payload: contactsToAdd,
            });
        });

        return contactsToAdd;
    }

    async update(
        id: string,
        payload: Partial<ContactPayload>,
        userId: string,
    ): Promise<ContactModel> {
        const locationService = new Location({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        const contactElement = await this.getByID(id);
        const { horses, image, removeAvatar } = payload;
        let locationElement: Model;

        let horseContactsToAdd: Model[] = [];
        let horseContactsToDelete: Model[] = [];

        const updateContactResult = await this.database.write(async () => {
            const { location } = payload;

            // create location if not already exists
            if (location && !isEmpty(location)) {
                const existingLocation = await locationService.getByParam(
                    'place_id',
                    location.placeID,
                );

                if (existingLocation.length > 0) {
                    locationElement = existingLocation[0];
                } else {
                    const locationCollection =
                        this.database.collections.get<LocationModel>(
                            'locations',
                        );

                    locationElement = await locationCollection.create(
                        (loc: LocationModel) => {
                            loc.placeID = location.placeID;
                            loc.name = location.name;
                            loc.lat = `${location.lat}`;
                            loc.lng = `${location.lng}`;
                            loc.url = location.url;
                            loc.utcOffset = location.utcOffset;
                            loc.userId = userId;
                            loc.organisationId = organisationID;
                        },
                    );

                    this.options.logDBAction({
                        message: 'Update contact - create location',
                        modelName: this.table,
                        payload: locationElement,
                    });
                }
            }

            const updatedContact = await contactElement.update((contact) => {
                contact.firstName =
                    capitalizeWords(payload.firstName) ||
                    contactElement.firstName;
                contact.lastName = getUpdatedFieldValue(
                    payload.lastName,
                    contactElement.lastName,
                    capitalizeWords,
                );
                // `description` is not used
                contact.description = getUpdatedFieldValue(
                    payload.description,
                    contactElement.description,
                );
                contact.role = getUpdatedFieldValue(
                    payload.role,
                    contactElement.role,
                );
                contact.phone = getUpdatedFieldValue(
                    payload.phone,
                    contactElement.phone,
                    stripSpecialChars,
                );
                contact.phonePrefix = getUpdatedFieldValue(
                    payload.phonePrefix,
                    contactElement.phonePrefix,
                );
                contact.email = getUpdatedFieldValue(
                    payload.email,
                    contactElement.email,
                );
                contact.businessName = getUpdatedFieldValue(
                    payload.businessName,
                    contactElement.businessName,
                );
                contact.address = getUpdatedFieldValue(
                    payload.address,
                    contactElement.address,
                );
                contact.comments = getUpdatedFieldValue(
                    payload.comments,
                    contactElement.comments,
                );
                contact.locationID =
                    payload.location === undefined
                        ? contact.locationID
                        : !isEmpty(locationElement) && locationElement?.id
                        ? locationElement.id
                        : null;
                contact.billable =
                    payload.billable === undefined
                        ? contactElement.billable
                        : payload.billable;
                contact.hidden =
                    payload.hidden === undefined
                        ? contactElement.hidden
                        : payload.hidden;
                contact.userId = contactElement.userId;
                contact.organisationId = contactElement.organisationId;
            });

            this.options.logDBAction({
                message: 'Update contact',
                modelName: this.table,
                payload: contactElement,
            });

            // Update related horses to the contact (horse_contacts)
            const initialHorses = await contactElement.horses.fetch();
            const horseContactsCollection =
                this.database.collections.get<HorseContactModel>(
                    'horse_contacts',
                );

            const horseContactService = new HorseContact({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });

            const horseContacts = await horseContactService.getByParam(
                'contact_id',
                contactElement.id,
            );

            if (!!horses) {
                await Promise.all(
                    horses.map(async (horse) => {
                        if (
                            !initialHorses.find(
                                (initialHorse) => initialHorse.id === horse.id,
                            )
                        ) {
                            const horseContactToAdd =
                                await horseContactsCollection.prepareCreate(
                                    (horseContact) => {
                                        horseContact.contactId =
                                            contactElement.id;
                                        horseContact.userId =
                                            contactElement.userId;
                                        horseContact.organisationId =
                                            contactElement.organisationId;
                                        horseContact.horseId = horse.id;
                                    },
                                );

                            horseContactsToAdd = [
                                ...horseContactsToAdd,
                                horseContactToAdd,
                            ];
                        }
                    }),
                );
            }

            if (!!horses) {
                await Promise.all(
                    horseContacts.map((horseContact) => {
                        if (
                            !horses.find(
                                ({ id }) => id === horseContact.horseId,
                            )
                        ) {
                            horseContactsToDelete = [
                                ...horseContactsToDelete,
                                horseContact.prepareMarkAsDeleted(),
                            ];
                        }
                    }),
                );
            }

            this.database.batch(
                ...horseContactsToAdd,
                ...horseContactsToDelete,
            );

            this.options.logDBAction({
                message: 'Update contact - create horse contacts',
                modelName: this.table,
                payload: horseContactsToAdd,
            });
            this.options.logDBAction({
                message: 'Update contact - delete horse contacts',
                modelName: this.table,
                payload: horseContactsToDelete,
            });

            return updatedContact;
        });

        if (removeAvatar) {
            await this.options.imageService.remove(id);
        }

        if (image && image.uri) {
            await this.options.imageService.uploadImage({
                image,
                entityID: id,
                entityType: 'Contact',
                annotationImage: '',
                userIDs: [userId],
                documentID: image.documentID,
                organisationID,
            });
        }

        return copyClassInstance(updateContactResult);
    }

    async updateField(
        id: string,
        fieldName: string,
        fieldValue: string | number | boolean,
    ) {
        const contactElement = await this.getByID(id);

        await this.database.write(async () => {
            await contactElement.update(
                (contact) => (contact[fieldName] = fieldValue),
            );

            this.options.logDBAction({
                message: `Update contact field ${fieldName}`,
                modelName: this.table,
                payload: contactElement,
            });
        });
    }

    async deleteByID(id: string) {
        const contactElement = await this.getByID(id);
        const contactHorses = await contactElement.horses.fetch();
        const contactEvents = await contactElement.events.fetch();

        await this.database.write(async () => {
            await contactElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete contact',
                modelName: this.table,
                payload: contactElement,
            });

            // Delete related horses to the contact (horse_contacts)
            if (contactHorses.length > 0) {
                let horseContactsToDelete: Model[] = [];

                const horseContactService = new HorseContact({
                    database: this.database,
                    imageService: this.options.imageService,
                    logDBAction: this.options.logDBAction,
                });

                const horseContacts = await horseContactService.getByParam(
                    'contact_id',
                    id,
                );

                await Promise.all(
                    horseContacts.map((horseContact) => {
                        horseContactsToDelete = [
                            ...horseContactsToDelete,
                            horseContact.prepareMarkAsDeleted(),
                        ];
                    }),
                );

                this.database.batch(...horseContactsToDelete);

                this.options.logDBAction({
                    message: 'Delete horse - delete horse contacts',
                    modelName: this.table,
                    payload: horseContactsToDelete,
                });
            }

            if (contactEvents.length > 0) {
                let eventContactsToDelete: Model[] = [];
                let eventContactRemindersToDelete: Model[] = [];

                const eventContactService = new EventContact({
                    database: this.database,
                    imageService: this.options.imageService,
                    logDBAction: this.options.logDBAction,
                });
                const eventContactReminderService = new EventContactReminder({
                    database: this.database,
                    imageService: this.options.imageService,
                    logDBAction: this.options.logDBAction,
                });

                const eventContacts = await eventContactService.fetchByParam(
                    'contact_id',
                    id,
                );

                await Promise.all(
                    eventContacts.map(async (eventContact) => {
                        const eventContactReminders =
                            await eventContactReminderService.getByParam(
                                'event_contact_id',
                                eventContact.id,
                            );

                        if (eventContactReminders.length > 0) {
                            eventContactReminders.map(
                                (eventContactReminder) => {
                                    eventContactRemindersToDelete = [
                                        ...eventContactRemindersToDelete,
                                        eventContactReminder.prepareMarkAsDeleted(),
                                    ];
                                },
                            );
                        }

                        eventContactsToDelete = [
                            ...eventContactsToDelete,
                            eventContact.prepareMarkAsDeleted(),
                        ];
                    }),
                );

                this.database.batch(
                    ...eventContactsToDelete,
                    ...eventContactRemindersToDelete,
                );

                this.options.logDBAction({
                    message: 'Delete contact - delete event contacts',
                    modelName: this.table,
                    payload: eventContactsToDelete,
                });
                this.options.logDBAction({
                    message: 'Delete contact - delete contact remiders',
                    modelName: this.table,
                    payload: eventContactRemindersToDelete,
                });
            }
        });

        await this.options.imageService.remove(id);
    }
}

export default Contact;
