import { Database } from '@nozbe/watermelondb';

import { AppDBServiceOptions } from '../../types/dbService';

class App {
    private database: Database;
    private options: AppDBServiceOptions;

    constructor(options: AppDBServiceOptions) {
        this.database = options.database;
        this.options = options;
    }

    async clearCollections() {
        try {
            await this.database.write(async () => {
                const collections = Object.values(
                    this.database.collections.map,
                );
                for (const collection of collections) {
                    await collection.query().destroyAllPermanently();
                }
            });

            this.options.logInfo(
                '(DB Clean) local DB collections has been cleared',
            );
        } catch {
            this.options.logInfo(
                '(DB Clean) local DB collections has not been cleared',
            );
        }
    }

    async clearDatabase() {
        try {
            await this.database.write(async () => {
                await this.database.unsafeResetDatabase();

                this.options.logInfo('(DB Clean) local DB has been cleared');
            });
        } catch {
            this.options.logInfo('(DB Clean) local DB has not been cleared');
        }
    }
}

export default App;
