import { ArrowForward } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import DatabaseService from 'shared/db/services/Database.web';
import EventContact from 'shared/db/services/EventContact';
import { EVENT_CONTACT_STATUS } from 'shared/types/EventContact';

import { ScheduleIcon } from '@/assets/svg';
import { useEventInvitationContext } from '@/context/EventInvitationContext';
import { useImagesContext } from '@/context/ImagesContext';
import { ROUTE } from '@/router/routes';
import Logger from '@/services/logger';
import { COLOR } from '@/theme/colors';

import Badge from '../Badge';

import { BannerButton, BannerLink } from './Banner';

const database = DatabaseService.getDatabase();

export type AppointmentBannerProps = {
    animate?: boolean;
};

function AppointmentsBanner({ animate }: AppointmentBannerProps) {
    const { attentionNeededEvents } = useEventInvitationContext();
    const { t } = useTranslation();
    const { ImagesService } = useImagesContext();

    const [badgeCount, setBadgeCount] = useState(attentionNeededEvents.length);

    const contactPageMatch = useMatch({
        path: ROUTE.contact,
    });

    const appointmentPageMatch = useMatch({
        path: ROUTE.appointment,
    });

    const id = appointmentPageMatch?.params.id;

    const isOnContactsPage = !!contactPageMatch;
    const isOnAppointmentPage = !!appointmentPageMatch;
    const isOnDetailsPage = isOnAppointmentPage;

    const shouldAnimate = animate && !appointmentPageMatch;

    const BannerComponent = appointmentPageMatch ? BannerButton : BannerLink;

    useEffect(() => {
        if (!isOnContactsPage) {
            const getEvents = async () => {
                const eventContactService = new EventContact({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                if (id) {
                    const contactEvents =
                        await eventContactService.getByContactAndStatus(
                            id,
                            EVENT_CONTACT_STATUS.REJECTED,
                        );

                    setBadgeCount(contactEvents.length);
                    return;
                }

                if (isOnAppointmentPage && id) {
                    const contactEvents =
                        await eventContactService.getByEventAndStatus(
                            id,
                            EVENT_CONTACT_STATUS.REJECTED,
                        );

                    setBadgeCount(contactEvents.length);
                    return;
                }

                setBadgeCount(attentionNeededEvents.length);
            };

            getEvents();
        }
    }, [
        attentionNeededEvents,
        id,
        ImagesService,
        isOnAppointmentPage,
        isOnContactsPage,
    ]);

    if (!badgeCount || isOnContactsPage) return null;

    return (
        <BannerComponent
            className={clsx(
                'absolute top-0 left-0 right-0 h-10 py-2 flex flex-row items-center',
                {
                    'cursor-pointer': !isOnDetailsPage,
                },
            )}
            style={{ backgroundColor: COLOR.sandwisp }}
        >
            <Box
                className={clsx(
                    'flex flex-row items-center px-6 w-full mx-auto',
                    {
                        'animate-banner-pulsing': shouldAnimate,
                    },
                )}
                sx={{
                    maxWidth: 'lg',
                }}
            >
                <ScheduleIcon height={20} color={COLOR.badgeTextGrey} />

                <Badge
                    count={badgeCount}
                    sx={{
                        height: '16px',
                        minWidth: '16px',
                        ml: '10px',
                        mr: '6px',
                    }}
                    fontSize={badgeCount > 9 ? 10 : 12}
                    isCircle
                />

                <Typography
                    sx={{
                        color: COLOR.badgeTextGrey,
                        fontSize: '13px',
                        fontWeight: 'bold',
                        lineHeight: 1,
                        mr: '12px',
                    }}
                >
                    {t(
                        isOnAppointmentPage
                            ? 'App:AppointmentBanner:pending_action'
                            : 'App:AppointmentBanner:label',
                    )}
                </Typography>

                {!isOnDetailsPage ? (
                    <ArrowForward
                        sx={{ color: COLOR.badgeTextGrey, height: '18px' }}
                    />
                ) : null}
            </Box>
        </BannerComponent>
    );
}

export default AppointmentsBanner;
