import { Model, Query, Relation } from '@nozbe/watermelondb';

import { ContactModel } from './Contacts';
import { EntryModel } from './Entries';
import { HorseModel } from './Horses';
import { Identificator } from './Identificator';
import { Location, LocationModel } from './Location';
import { UserModel } from './User';
import { EVENT_CONTACT_STATUS } from './EventContact';
import { ImageService } from './imageService';
import { DBWriterLoggerFunction } from './logger';

export enum EventCategory {
    service = 'service',
    standard = 'standard',
}

export type InvitationStatus = 'not_sent' | 'sending' | 'sent';

export type ContactInvitation = {
    sms: boolean;
    email: boolean;
    smsAt: string | null;
    emailAt: string | null;
    smsStatus: InvitationStatus;
    emailStatus: InvitationStatus;
};

export type ContactInvitations = {
    [contactId: string]: ContactInvitation;
};

export type ContactRSVPStatus = {
    [contactId: string]: EVENT_CONTACT_STATUS;
};

export type EventModel = {
    address: string | null;
    contacts: Query<ContactModel>;
    sendInvitesToContactsAt: string;
    contactsReminder: boolean;
    createdAt: string;
    endsTime: string;
    entries: Query<EntryModel>;
    horses: Query<HorseModel>;
    id: string;
    locationID?: string | null;
    notes: string | null;
    organisationId: string;
    ownersReminder: boolean;
    startsTime: string;
    title?: string | null;
    updatedAt: string;
    userId: string;
    userReminder?: string | null;
    location: Relation<LocationModel>;
    timezone?: string;
    users: Query<UserModel>;
    category: EventCategory;
    isRecurring: boolean;
    recurrencePattern?: string;
    recurrenceEndDate?: string;
    originalEventId?: string;
} & Model;

export type CalendarEvent = {
    start: Date;
    end: Date;
    color: string;
    eventModel: EventModel;
};

export type ContactInvitationsPayload = {
    [contactId: string]: {
        sms: boolean;
        email: boolean;
    };
};

export type EventPayload = {
    address?: string | null;
    category?: EventCategory;
    contacts?: Identificator[];
    contactsReminder: boolean;
    endsTime: string;
    horses?: Identificator[];
    location?: Location | null;
    notes?: string | null;
    ownersReminder: boolean;
    startsTime: string;
    title?: string | null;
    userReminder?: string | null;
    membersIds?: string[];
    createEntries?: boolean;
    timezone?: string;
    invitations?: ContactInvitationsPayload;
    isRecurring?: boolean;
    recurrencePattern?: string;
    recurrenceEndDate?: string;
    originalEventId?: string;
};

export type CalculateEventStatusParams = {
    database: any;
    imageService: ImageService;
    logDBAction: DBWriterLoggerFunction;
    logError: any;
    eventId: string;
};

export type RSVPEventStatus = {
    contactsCount: number;
    contactToShow: ContactModel | null;
    confirmedNumber?: number;
};

export type CalculateEventStatusResult = {
    awaiting: number;
    confirmed: RSVPEventStatus | null;
    declined: RSVPEventStatus | null;
};

export type EventRecord = {
    id: string;
    user_id: string;
    organisation_id: string;
    location_id: string;
    title?: string;
    address?: string;
    starts_time: string;
    ends_time: string;
    notes?: string;
    user_reminder?: string;
    send_reminders_to_contacts: boolean;
    send_reminders_to_owners: boolean;
    send_invites_to_contacts: boolean;
    send_invites_to_contacts_at: string;
    send_invites_to_owners: boolean;
    timezone?: string;
    category: string;
    created_at: number;
    updated_at: number;
    is_recurring: boolean;
    recurrence_pattern?: string;
    recurrence_end_date?: string;
    original_event_id?: string;
};
