// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
    lazy,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Contact extends Model {
    static table = 'contacts';

    static associations = {
        horse_contacts: { type: 'has_many', foreignKey: 'contact_id' },
        event_contacts: { type: 'has_many', foreignKey: 'contact_id' },
        organisations: { type: 'belongs_to', key: 'organisation_id' },
        invoices: { type: 'has_many', foreignKey: 'contact_id' },
        locations: { type: 'belongs_to', key: 'location_id' },
    };

    @lazy
    horses = this.collections
        .get('horses')
        .query(Q.on('horse_contacts', 'contact_id', this.id));

    @lazy
    events = this.collections
        .get('events')
        .query(Q.on('event_contacts', 'contact_id', this.id));

    @lazy
    invoices = this.collections
        .get('invoices')
        .query(Q.where('contact_id', this.id));

    @lazy
    locations = this.collections
        .get('locations')
        .query(Q.where('id', this.locationID));

    @field('first_name') firstName;
    @field('last_name') lastName;
    @field('description') description;
    @field('role') role;
    @field('business_name') businessName;
    @field('phone') phone;
    @field('phone_prefix') phonePrefix;
    @field('email') email;
    @field('address') address;
    @field('comments') comments;
    @field('location_id') locationID;
    @field('billable') billable;
    @field('hidden') hidden;
    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @field('synced') synced;

    @relation('locations', 'location_id') location;
    @relation('organisations', 'organisation_id') organisation;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}

export type ContactSimple = {
    id: string;
    userId: string;
    organisationId: string;
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    phone: string;
    phonePrefix: string;
    billable: bool;
    hidden: bool;
    role: string;
};
